import React from 'react';
import useTranslations from '../useTranslations';


import * as S from './styled';

const Banner = () => {
  const { buttonUrl, button } = useTranslations();
  
  return (
    <S.BannerWrapper>
        <S.Container>

        <S.NavigationButton href={buttonUrl}  rel="noopener noreferrer">
          {button}
        </S.NavigationButton>

        </S.Container>
    </S.BannerWrapper>
  );
};

export default Banner;
