import React, {useEffect, useRef, useState} from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import PostItem from '../components/PostItem';
import FeaturedItem from '../components/FeaturedItem';
import TitlePage from '../components/TitlePage';
import useTranslations from '../components/useTranslations';
import Banner from '../components/Banner';
import Container from '../components/Container';

import * as S from '../components/Styles/styled';

const Index = ({ data: { blogData, pageData } }) => {
  // useTranslations is aware of the global context (and therefore also "locale")
  // so it'll automatically give back the right translations
  const {
    latestPosts,
    allPosts,
    aboutKunera,
    aboutKuneraLink
  } = useTranslations();

  const postList = blogData.edges;
  const pageContent = pageData.frontmatter;
  const newsIsHidden = pageData.frontmatter.newsIsHidden;
  const listDivs = useRef([]); 

  const [featured] = useState({
    isHidden: pageData.frontmatter.featuredIsHidden,
    list: pageData.frontmatter.featured.items
  });

  useEffect(() => {

    const showRandomFeatures = (visible, all) => {
      if (visible <= all && !featured.isHidden) {
        let arr = [];
          while(arr.length < visible){
              let r = Math.floor(Math.random() * all);
              if(arr.indexOf(r) === -1) arr.push(r);
          }
          for (let i = 0; i < arr.length; i++) {
            listDivs.current[arr[i]].style.display = 'block'
          }      
      }
    }
    showRandomFeatures(3,featured.list.length)
  

  }, [featured])

  return (
    <>

      <SEO title="Home" />
      
      <Banner/>

      <S.IntroWrapper>
        <Container>
          

          <TitlePage text={pageContent.title} />
          <p>{pageContent.description}</p>

          <S.MoreLinkBold to={aboutKuneraLink}>{aboutKunera} →</S.MoreLinkBold>

        </Container>
      </S.IntroWrapper>

      { featured.isHidden || featured.list.length < 3 ? null : 
        <Container>

          <S.SubTitle>
            {pageContent.featured.title} 
          </S.SubTitle>       

          <S.FeaturedWrapper>
          {featured.list.map(
            ({
              title,
              text,
              image,
              url
            }, index) => (
              <div key={index} style={{display:'none'}} ref={(element) => listDivs.current.push(element)}>
                <FeaturedItem
                title={title}
                text={text}
                image={image}
                url={url}
                
              />
              </div>
            ),
          )}
          </S.FeaturedWrapper>



        </Container>
      }


      { newsIsHidden ? null : 
     
        <Container>
          <S.SubTitle>
            {latestPosts} 
          </S.SubTitle>
          <S.ListWrapper>
            {postList.map(
              ({
                node: {
                  frontmatter: {
                    date,
                    description,
                    title,
                    image,
                  },
                  fields: { slug },
                },
              }) => (
                  <PostItem
                    slug={`/blog/${slug}`}
                    date={date}
                    title={title}
                    description={description}
                    image={image}
                    key={slug}
                  />
                ),
            )}
          </S.ListWrapper>

          <S.MoreLinkBold to={`/blog/`}>{allPosts} →</S.MoreLinkBold>
        </Container>     
     
      }

 


    </>
  );
};

export default Index;

export const query = graphql`
  query Index($locale: String!, $dateFormat: String!, ) {    
    blogData:allMarkdownRemark(
      filter: {
        fields: { locale: { eq: $locale } }
        fileAbsolutePath: {regex: "/(blog)\/.*\\\\.md$/"}
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            image
            date(formatString: $dateFormat, locale:$locale)

          }
          fields {
            locale
            slug
          }
        }
      }
    }  
    pageData:markdownRemark(
      fields: { locale: { eq: $locale } }
      fileAbsolutePath: {regex: "/(home)\/.*\\\\.md$/"}
    ) {
      frontmatter {
        title
        description
        newsIsHidden
        featuredIsHidden
        featured {
          title
          items {
            title
            text
            image 
            url
          }
        }
      }
    }      
  }
`;
