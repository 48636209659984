import styled from 'styled-components';
import media from 'styled-media-query';
import bannerImage from '../../images/kunera-banner-image.jpg';

export const BannerWrapper = styled.div`
  background-image: url(${bannerImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  height: 200px;
  ${media.greaterThan('small')`
    height: 300px;
  `}
  ${media.greaterThan('medium')`
    height: 400px;
  `}
  ${media.greaterThan('large')`
    height: 500px;
  `}
  ${media.greaterThan('huge')`
    height: 600px;
  `}      
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-width: var(--width-container);
  margin: 0 auto;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0 var(--space);

  ${media.greaterThan('large')`
    padding: 0 var(--space-sm);
  `}

`;

export const NavigationButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  padding: 0 2.5rem;
  height: 44px;
  border-radius: 22px;
  background: var(--color-green);
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  transition: .3s ease-in-out;
  &:hover{
    background-color: var(--hover-color-green);
    color: var(--color-white);
  }

  ${media.greaterThan('medium')`  
    font-size: 2.2rem;
    margin: 40px 0;
    height: 72px;
    border-radius: 36px;
    padding: 0 4rem;
  `}
`;
