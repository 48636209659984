import React from 'react';
import PropTypes from 'prop-types';
import useResponsiveImage from '../useResponsiveImage';
import useTranslations from '../../components/useTranslations';
import * as S from './styled';

const FeaturedItem = ({
  image,
  title,
  text,
  url,
}) => {

  const {
    view
  } = useTranslations();  

  return (
    <S.ItemLink href={url} rel="noopener noreferrer">

      <S.ItemImgWrapper>

      <S.ItemImg
        fluid={useResponsiveImage(image)}
        alt={title}
      />
      
      </S.ItemImgWrapper>

      <S.ItemTitle>
        {title}
      </S.ItemTitle>

      <S.ItemText>
        {text}
      </S.ItemText>

      <S.ReadMore>{view} →</S.ReadMore>

    </S.ItemLink>
  );
};

FeaturedItem.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default FeaturedItem;
